<template>
  <!-- 批量操作 -->
  <div class="btn_handle">
    <div>
      <el-button
        class="mr-10"
        size="small"
        v-if="params.platform == 'snapchat'"
        type="primary"
        @click="goto"
        >创建广告</el-button
      >
      <el-dropdown
        @command="handleCommand"
        v-if="level != 'ad'"
        :show-timeout="0"
      >
        <el-button size="small"> 定时 <i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="open"
            :disabled="timeOpenDisabledFlag"
            >定时开启</el-dropdown-item
          >
          <el-dropdown-item
            command="close"
            :disabled="timeOpenDisabledFlag"
            >定时关闭</el-dropdown-item
          >
          <el-dropdown-item
            command="amount"
            v-if="level == 'adset'"
            :disabled="selectedRowScopes.length == 0"
            >修改出价</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button
        class="ml-10"
        size="small"
        @click="deleteIds"
        :disabled="!selectedRowScopes.length"
        >删除</el-button
      > -->

      <el-dropdown
        :disabled="timeOpenDisabledFlag "
        @command="commandStatus"
        :show-timeout="0"
      >
        <el-button
          class="ml-10"
          size="small"
          >批量<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="DELETED">删除</el-dropdown-item>
          <el-dropdown-item command="ACTIVE">开启</el-dropdown-item>
          <el-dropdown-item command="PAUSED">暂停</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-button
        class="ml-10"
        size="small"
        @click="toggeleShowAbnormal"
        >异常账户
      </el-button>
      <el-button
        class="ml-10"
        size="small"
        @click="showTask = true"
        >任务列表
      </el-button>
      <el-dropdown
        @command="commandColumn"
        :show-timeout="0"
        trigger="click"
      >
        <el-button
          class="ml-10"
          size="small"
          >自定义列表 <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="-1"
            class="border-b"
            >定制列组合</el-dropdown-item
          >
          <el-dropdown-item
            v-for="(myColumn, index) in myColumns"
            :key="myColumn.id"
            :command="myColumn.id"
            :class="[myColumn.used ? 'active' : '']"
          >
            <div class="columnName">
              <span>{{ myColumn.name }}</span>
              <div v-if="myColumn.id != 0">
                <p>
                  <i
                    class="el-icon-edit"
                    @click.stop="editColumns(myColumn)"
                  ></i>
                  <i
                    class="el-icon-delete"
                    @click.stop="deleColumns(myColumn)"
                  ></i>
                </p>
              </div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <abnormal :show.sync="showAbnormal"></abnormal>
    <taskList :show.sync="showTask"></taskList>
    <cusColumn
      :visible.sync="showCusColumn"
      :data="editColmn"
      @updateSuccess="getMyColumns"
    ></cusColumn>
  </div>
</template>

<script>
import abnormal from './abnormal.vue';
import taskList from './taskList.vue';
import cusColumn from './cusColumn.vue';
import { mutateStatusMultiple, myColumns, setUsedColumn, columnDelete, dailySaleOrderTrend } from '@/api/multiPlatform';
export default {
  props: {
    selectedRowScopes: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: '',
    },
    isShowSetBidAmount: {
      type: Boolean,
      default: false,
    },
    params: Object,
  },
  components: { abnormal, taskList, cusColumn },
  data() {
    return {
      setTimeIds: [],
      initData: null,
      showSetBidAmount: false,
      showAbnormal: false, // 显示异常信息
      showTask: false, // 任务列表
      showCusColumn: false, // 自定义列表设置
      myColumns: [],
      editColmn: {},
    };
  },
  computed: {
    timeOpenDisabledFlag() {
        return this.selectedRowScopes[0]?.ad?.smartPerformanceCampaign && this.level === 'adset' ? true : false;
    },
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'close':
          this.$emit('setShowOpenClock', { type: 4, data: this.selectedRowScopes, status: false, isClock: true });
          break;
        case 'open':
          this.$emit('setShowOpenClock', { type: 1, data: this.selectedRowScopes, status: true, isClock: true });
          break;
        case 'amount': // 出价
          this.amountClick();
      }
    },
    deleteIds() {
      const statusKeys = {
        campaign: 'adCampaignStatus',
        adset: 'adGroupStatus',
        ad: 'adStatus',
      };
      const canDelete = this.selectedRowScopes.filter((item) => item.ad[statusKeys[this.level]] == 'ACTIVE');
      if (canDelete.length) {
        return this.$message.error('存在投放中的系列或组，不允许删除');
      }

      this.$confirm('确认删除选择的系列', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.mutateStatus('DELETED');
      });
    },
    mutateStatus(status) {
      let loading = this.$loading();
      const data = this.selectedRowScopes.map((item) => ({
        platform: item.ad.platform,
        campaignId: item.ad.campaignId,
        adsetId: item.ad.adGroupId,
        adId: item.ad.adId,
        accountId: item.ad.adAccountId,
        status: status,
        level: this.level,
        clientType: 'pc',
        functionType: 'manual',
      }));
      mutateStatusMultiple(data)
        .then(() => {
          this.$emit('reload');
        })
        .finally(() => {
          loading.close();
        });
    },

    toggeleShowAbnormal() {
      this.showAbnormal = true;
    },
    amountClick() {
      let types = [];
      let notCanEdit = [];
      this.selectedRowScopes.forEach((element) => {
        types.push(element.ad.bidModelName);
        if (element.ad.platform == 'google' && element.ad.objectTive == 'VIDEO') {
          notCanEdit.push(element);
        }
        if (!element.ad.bidAmount) {
          notCanEdit.push(element);
        }
      });
      const len = Array.from(new Set(types));
      if (len > 1) {
        return this.$message.error('只能修改相同类型出价');
      }
      if (notCanEdit.length) {
        return this.$message.error('存在不支持的出价类型');
      }
      this.$emit('editbidAmount', this.selectedRowScopes);
    },
    getMyColumns() {
      let params = {
        sysType: 'MULTI_PLATFORM',
      };
      myColumns(params).then((res) => {
        this.myColumns = res.data;
        this.$emit(
          'getCurrentColumn',
          res.data.find((item) => item.used),
        );
      });
    },
    commandStatus(status) {
      switch (status) {
        case 'DELETED':
          this.deleteIds();
          break;
        default:
          this.mutateStatus(status);
          break;
      }
    },
    commandColumn(id) {
      if (id == -1) {
        this.editColumns();
        return;
      }
      let params = {
        id,
        sysType: 'MULTI_PLATFORM',
      };
      let loading = this.$loading();
      setUsedColumn(params)
        .then((res) => {
          this.getMyColumns();
        })
        .finally(() => {
          loading.close();
        });
    },
    editColumns(column) {
      this.editColmn = column;
      this.showCusColumn = true;
    },
    deleColumns(column) {
      columnDelete({ id: column.id }).then((res) => {
        this.getMyColumns();
      });
    },
    goto() {
      if (this.params.platform == 'snapchat') {
        this.$store.dispatch('app/closeSideBar');
        this.$router.push('/snapchat/create');
      }
    },
  },
  mounted() {
    this.getMyColumns();
  },
};
</script>

<style lang="scss" scope>
.btn_handle {
  margin-bottom: 15px;
}
.active {
  background: #ecf5ff;
  color: #66b1ff;
}
.border-b {
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.columnName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;

  &:hover {
    p {
      display: block;
    }
  }

  div {
    p {
      flex: 60px 0 0;
      display: none;

      i {
        margin: 0 5px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>
