<template>
  <el-dialog
    :visible="show"
    width="500px"
    title="调整出价"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="close"
  >
    <div v-loading="loading">
      <el-form
        :model="formData"
        :rules="rules"
        label-width="120px"
        ref="form"
      >
        <el-form-item
          class="flex-1"
          v-for="data in _dataEdit"
          :key="data.bidModel"
          :prop="data.bidModel"
          :label="data.bidModelName"
        >
          <el-input
            v-model="formData[data.bidModel]"
            v-to-fixed="data.scal || 2"
          >
            <template
              slot="append"
              v-if="data.bidHasCurrency"
              >{{ data.currency }}</template
            >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dp-flex justify-end">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { queryConfigDataCheck, mutateBudget, batchMutateBudget } from '@/api/multiPlatform';
import { defaultRules } from '@/utils/enmu.js';
export default {
  props: {
    show: Boolean,
    level: String,
    data: {
      type: Object || Array,
      default: () => ({ ad: {}, statistics: {} }),
    },
  },
  data() {
    return {
      formData: {
        bidAmount: '',
      },
      loading: false,
      checkRules: defaultRules,
    };
  },
  computed: {
    rules() {
      let rules = {};
      let bidMin = this.checkRules.bidMin;
      let bidMax = this.checkRules.bidMax;
      this._dataEdit.forEach((data) => {
        rules[data.bidModel] = [
          {
            validator: (rule, value, callback) => {
              if (data.bidModel == 'LOWEST_COST_WITH_MIN_ROAS') {
                bidMin = 0.01;
                bidMax = 1000;
              }
              if (value / 1 >= bidMin && bidMax >= value / 1) {
                callback();
              }
              callback(new Error());
            },
            message: `请输入${bidMin} - ${bidMax}之间的数字`,
            trigger: ['blur', 'change'],
          },
          {
            required: true,
            message: '请输入出价',
          },
        ];
      });
      return rules;
    },
    // 批量
    isBatch() {
      return Array.isArray(this.data);
    },
    _dataEdit() {
      let types = [];
      if (this.isBatch) {
        this.data.forEach((item) => {
          const { bidModel, bidModelName, bidHasCurrency, bidAmount, objectiveEnum, platform } = item.ad;
          if (types.findIndex((item) => item.bidModel == bidModel) == -1) {
            types.push({
              bidModelName,
              bidAmount,
              bidModel,
              bidHasCurrency,
              objectiveEnum,
              platform,
              currency: item.currency,
              scal: this.checkRules.budgetScale,
            });
          }
        });
      } else {
        const { bidModel, bidModelName, bidHasCurrency, bidAmount, objectiveEnum, platform } = this.data.ad;
        types.push({
          bidModelName,
          bidAmount,
          bidModel,
          bidHasCurrency,
          objectiveEnum,
          platform,
          currency: this.data.currency,
          scal: this.checkRules.budgetScale,
        });
      }
      return types;
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    getCheckRules() {
      let params = {
        platform: this._dataEdit[0].platform,
        currency: this._dataEdit[0].currency,
        objectiveEnum: this._dataEdit[0].objectiveEnum,
      };
      console.log('params', params);
      queryConfigDataCheck(params).then((res) => {
        this.checkRules = res.data || defaultRules;
      });
    },
    // 批量修改保存
    batchSave() {
      const params = this.data.map((item) => {
        return {
          platform: item.ad.platform,
          level: this.level,
          accountId: item.ad.adAccountId,
          campaignId: item.ad.campaignId,
          adsetId: item.ad.adGroupId,
          bidAmount: this.formData[item.ad.bidModel],
          clientType: 'pc',
          functionType: 'manual',
        };
      });

      this.loading = true;
      batchMutateBudget(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            this.data.forEach((element, index) => {
              this.$set(this.data[index].ad, 'bidAmount', this.formData[element.ad.bidModel]);
            });
            this.close();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 单个修改保存
    save() {
      let params = {
        platform: this.data.ad.platform,
        level: this.level,
        accountId: this.data.ad.adAccountId,
        campaignId: this.data.ad.campaignId,
        adsetId: this.data.ad.adGroupId,
        bidAmount: this.formData[this.data.ad.bidModel],
        bidModel: this.data.ad.bidModel,
        clientType: 'pc',
        functionType: 'manual',
        smartPerformanceCampaign: this.data.ad.smartPerformanceCampaign,
      };

      this.loading = true;
      mutateBudget(params)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            this.$set(this.data.ad, 'bidAmount', this.formData[this.data.ad.bidModel]);
            this.close();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sure() {
      console.log(this.formData);

      if (this.loading) return;
      try {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.isBatch) {
              this.batchSave();
            } else {
              this.save();
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(bool) {
        if (bool) {
          this.loading = false;
          this.getCheckRules();
          if (!this.isBatch) {
            let data = this._dataEdit[0];
            this.$set(this.formData, data.bidModel, data.bidAmount);
          }
        }
      },
    },
  },
};
</script>
